var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_balance_sheet") } },
    [
      _c(
        "a-form",
        {
          attrs: {
            form: _vm.form,
            labelCol: { span: 2 },
            wrapperCol: { span: 8 },
            labelAlign: "left"
          }
        },
        [
          _c(
            "a-form-item",
            { attrs: { label: _vm.$t(_vm.formRules.asOfDate.label) } },
            [
              _c("a-date-picker", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: _vm.formRules.asOfDate.decorator,
                    expression: "formRules.asOfDate.decorator"
                  }
                ],
                attrs: {
                  name: _vm.formRules.asOfDate.name,
                  placeholder: _vm.$t(_vm.formRules.asOfDate.placeholder),
                  format: _vm.DEFAULT_DATE_FORMAT
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "danger" },
                      on: { click: _vm.handleReset }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_reset")) + " ")]
                  ),
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        loading: _vm.loading.loadingDownload
                      },
                      on: { click: _vm.handleDownload }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }